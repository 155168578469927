<template>
  <div v-for="item in tuiguang" :key="item">



    <div class="mheader" id="ban" v-for="list in item.title" :key="list">
      <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td width="72" height="44">
            
              <div class="mlback"></div>
      
          </td>
          <td align="center">{{ list.title1 }}</td>
          <td width="72"></td>
        </tr>
      </table>
    </div>
    <h1 class="h1" v-for="list in item.biaoti" :key="list">{{ list.biaoti1 }}</h1>

    <div class="main">
      <div style="border:1px solid #F00;padding:10px;font-size:16px;margin:10px 0;" v-for="list in item.neirong" :key="list">
        {{ list.neirong1 }}<span
          style="color:red; font-weight:bold; background: #FF0;">{{ list.neirong2 }}</span>{{ list.neirong3 }}
      </div>


      <div class="postinfo" v-for="list in item.pl" :key="list">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td width="52" rowspan="2">
              <img :src="list.pl1"  class="_avatar" width="42" height="42"/>
              
            </td>
            <td height="25"><span class=n>{{ list.pl2 }}</span></td>
            <td width="100" style="font-size:14px;text-align:center;">{{ list.pl3 }}</td>
          </tr>
          <tr>
            <td style="font-size:14px;">{{ list.pl4}}</td>
          </tr>
        </table>
      </div>


      <div id="content" v-for="list in item.plcon" :key="list">
        <p>{{ list.plcon1 }}</p>
        <p>{{ list.plcon2 }} </p>
        <p>{{ list.plcon3 }} </p>
        <p>{{ list.plcon4 }}</p>
        <p>{{ list.plcon5 }}</p>
        <p>{{ list.plcon6 }}<font color="#FF0000" size="+1">{{ list.plcon7 }}</font>{{ list.plcon8 }} </p>
        <p>{{ list.plcon9}}</p>
        <p>{{ list.plcon10 }}</p>
        <p>{{ list.plcon11}}<font color="#FF0000" size="+1">{{ list.plcon7 }}</font>{{ list.plcon12 }}</p>


      </div>



      <div id="replyContent" class="replyContent"  >
        <div class="tl clearfix" v-for="list in item.pinglun" :key="list">
          <div class="tlt">{{ list.pinglun1 }}<span>(<b id="replynum">{{ list.pinglun2}}</b>)</span></div>
          <div id="ordermenu" class="tlm" style="display:none;"></div>
        </div>

        <ul id="replylist" class="rlist"  v-for="list in item.louceng" :key="list">
          <li id="reply2">
            <dl>
              <dt>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td width="46" align="center" rowspan="2">
                      <img :src="list.louceng2_1"  class="_avatar" width="36" height="36"/>
                       </td>
                    <td height="18"><span>{{ list.louceng2_2 }}</span></td>
                  </tr>
                  <tr>
                    <td style="font-size:14px;padding-top:5px;">{{ list.louceng2_3 }}</td>
                  </tr>
                </table>
              </dt>
              <dd>
                <div class="floor">{{ list.louceng2_4 }}</div>
              </dd>
            </dl>
            <div class="rc">
              <p>{{ list.louceng2_5 }}</p>
            </div>
            <div class="ft">
              <div class="d">{{ list.louceng2_6 }}</div>
              <div class="rbt" onClick="showReply(2,87964533);">{{ list.louceng_huifu }}</div>
            </div>
          </li>



          <li id="reply3">
            <dl>
              <dt>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td width="46" align="center" rowspan="2"><img :src="list.louceng3_1"  class="_avatar" width="36" height="36"/></td>
                    <td height="18"><span>{{ list.louceng3_2}}</span></td>
                  </tr>
                  <tr>
                    <td style="font-size:14px;padding-top:5px;">{{ list.louceng3_3 }}</td>
                  </tr>
                </table>
              </dt>
              <dd>
                <div class="floor">{{ list.louceng3_4 }}</div>
              </dd>
            </dl>
            <div class="rc">
              <p>
                {{ list.louceng3_5 }}
              </p>
            </div>
            <div class="ft">
              <div class="d">{{ list.louceng3_6 }}</div>
              <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
            </div>
          </li>
        <li id="reply3">
              <dl>
                <dt>
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td width="46" align="center" rowspan="2"><img :src="list.louceng4_1"  class="_avatar" width="36" height="36"/></td>
                      <td height="18"><span>{{ list.louceng4_2 }}</span></td>
                    </tr>
                    <tr>
                      <td style="font-size:14px;padding-top:5px;">{{ list.louceng4_3 }}</td>
                    </tr>
                  </table>
                </dt>
                <dd>
                  <div class="floor">{{ list.louceng4_4 }}</div>
                </dd>
              </dl>
              <div class="rc">
                <p>
                  {{ list.louceng4_5 }}
                </p>
              </div>
              <div class="ft">
                <div class="d">{{ list.louceng4_6 }}</div>
                <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
              </div>
            </li>






          <li id="reply3">
            <dl>
              <dt>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td width="46" align="center" rowspan="2"><img :src="list.louceng5_1"  class="_avatar" width="36" height="36"/></td>
                    <td height="18"><span>{{ list.louceng5_2 }}</span></td>
                  </tr>
                  <tr>
                    <td style="font-size:14px;padding-top:5px;">{{ list.louceng5_3 }}</td>
                  </tr>
                </table>
              </dt>
              <dd>
                <div class="floor">{{ list.louceng5_4}}</div>
              </dd>
            </dl>
            <div class="rc">
              <p>{{ list.louceng5_5 }}
                <font color="#FF0000" size="+1">{{ list.louceng_zhuanjia }}</font>{{ list.louceng5_6 }}
              </p>
            </div>
            <div class="ft">
              <div class="d">{{ list.louceng5_7 }}</div>
              <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
            </div>
          </li>



    <li id="reply3">
                <dl>
                  <dt>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="46" align="center" rowspan="2"><img :src="list.louceng6_1"  class="_avatar" width="36" height="36"/></td>
                        <td height="18"><span>{{ list.louceng6_2 }}</span></td>
                      </tr>
                      <tr>
                        <td style="font-size:14px;padding-top:5px;">{{ list.louceng6_3 }}</td>
                      </tr>
                    </table>
                  </dt>
                  <dd>
                    <div class="floor">{{ list.louceng6_4 }}</div>
                  </dd>
                </dl>
                <div class="rc">
                  <p>
                    {{ list.louceng6_5 }}
                  </p>
                </div>
                <div class="ft">
                  <div class="d">{{ list.louceng6_6 }}</div>
                  <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                </div>
              </li>


      <li id="reply3">
              <dl>
                <dt>
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td width="46" align="center" rowspan="2"><img :src="list.louceng7_1"  class="_avatar" width="36" height="36"/></td>
                      <td height="18"><span>{{ list.louceng7_2 }}</span></td>
                    </tr>
                    <tr>
                      <td style="font-size:14px;padding-top:5px;">{{ list.louceng7_3 }}</td>
                    </tr>
                  </table>
                </dt>
                <dd>
                  <div class="floor">{{ list.louceng7_4 }}</div>
                </dd>
              </dl>
              <div class="rc">
                <p><b>{{ list.louceng7_5 }}</b> {{ list.louceng7_6 }}
                </p>
              </div>
              <div class="ft">
                <div class="d">{{ list.louceng7_7 }}</div>
                <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
              </div>
            </li>

  





          <li id="reply4">
            <dl>
                <dt>
                  <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td width="46" align="center" rowspan="2"><img :src="list.louceng8_1"  class="_avatar" width="36" height="36"/></td>
                      <td height="18"><span>{{ list.louceng8_2 }}</span></td>
                    </tr>
                    <tr>
                      <td style="font-size:14px;padding-top:5px;">{{ list.louceng8_3 }}</td>
                    </tr>
                  </table>
                </dt>
                <dd>
                  <div class="floor">{{ list.louceng8_4 }}</div>
                </dd>
              </dl>
              <div class="rc">
                <p>{{ list.louceng8_5 }}
                  <font color="#FF0000" size="+1">{{ list.louceng_zhuanjia }}</font>{{ list.louceng8_6 }}
                </p>
              </div>
              <div class="ft">
                <div class="d">{{ list.louceng8_7 }}</div>
                <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
              </div>
          </li>


     <li id="reply5">
              <dl>
                  <dt>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <td width="46" align="center" rowspan="2"><img :src="list.louceng9_1"  class="_avatar" width="36" height="36"/></td>
                        <td height="18"><span>{{ list.louceng9_2 }}</span></td>
                      </tr>
                      <tr>
                        <td style="font-size:14px;padding-top:5px;">{{ list.louceng9_3 }}</td>
                      </tr>
                    </table>
                  </dt>
                  <dd>
                    <div class="floor">{{ list.louceng9_4 }}</div>
                  </dd>
                </dl>
                <div class="rc">
                  <p>{{ list.louceng9_5 }}
                    <font color="#FF0000" size="+1">{{ list.louceng_zhuanjia }}</font>{{ list.louceng9_6 }}
                  </p>
                </div>
                <div class="ft">
                  <div class="d">{{ list.louceng9_7 }}</div>
                  <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                </div>
            </li>

          <li id="reply6">
           <dl>
                    <dt>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td width="46" align="center" rowspan="2"><img :src="list.louceng10_1"  class="_avatar" width="36" height="36"/></td>
                          <td height="18"><span>{{ list.louceng10_2 }}</span></td>
                        </tr>
                        <tr>
                          <td style="font-size:14px;padding-top:5px;">{{ list.louceng10_3 }}</td>
                        </tr>
                      </table>
                    </dt>
                    <dd>
                      <div class="floor">{{ list.louceng10_4 }}</div>
                    </dd>
                  </dl>
                  <div class="rc">
                    <p>
                      {{ list.louceng10_5 }}
                    </p>
                  </div>
                  <div class="ft">
                    <div class="d">{{ list.louceng10_6 }}</div>
                    <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                  </div>
          </li>


          <li id="reply3">
                  <dl>
                      <dt>
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td width="46" align="center" rowspan="2"><img :src="list.louceng11_1"  class="_avatar" width="36" height="36"/></td>
                            <td height="18"><span>{{ list.louceng11_2 }}</span></td>
                          </tr>
                          <tr>
                            <td style="font-size:14px;padding-top:5px;">{{ list.louceng11_3 }}</td>
                          </tr>
                        </table>
                      </dt>
                      <dd>
                        <div class="floor">{{ list.louceng11_4 }}</div>
                      </dd>
                    </dl>
                    <div class="rc">
                      <p>
                        {{ list.louceng11_5 }}
                      </p>
                    </div>
                    <div class="ft">
                      <div class="d">{{ list.louceng11_6 }}</div>
                      <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                    </div>
          </li>
          <li id="reply7">
                <dl>
                        <dt>
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                              <td width="46" align="center" rowspan="2"><img :src="list.louceng12_1"  class="_avatar" width="36" height="36"/></td>
                              <td height="18"><span>{{ list.louceng12_2 }}</span></td>
                            </tr>
                            <tr>
                              <td style="font-size:14px;padding-top:5px;">{{ list.louceng12_3 }}</td>
                            </tr>
                          </table>
                        </dt>
                        <dd>
                          <div class="floor">{{ list.louceng12_4 }}</div>
                        </dd>
                      </dl>
                      <div class="rc">
                        <p>
                          {{ list.louceng12_5 }}
                        </p>
                      </div>
                      <div class="ft">
                        <div class="d">{{ list.louceng12_6 }}</div>
                        <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                      </div>
          </li>
          <li id="reply8">
          <dl>
                    <dt>
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td width="46" align="center" rowspan="2"><img :src="list.louceng13_1"  class="_avatar" width="36" height="36"/></td>
                          <td height="18"><span>{{ list.louceng13_2 }}</span></td>
                        </tr>
                        <tr>
                          <td style="font-size:14px;padding-top:5px;">{{ list.louceng13_3 }}</td>
                        </tr>
                      </table>
                    </dt>
                    <dd>
                      <div class="floor">{{ list.louceng13_4 }}</div>
                    </dd>
                  </dl>
                  <div class="rc">
                    <p>{{ list.louceng13_5 }}
                      <font color="#FF0000" size="+1">{{ list.louceng_zhuanjia }}</font>{{ list.louceng13_6 }}
                    </p>
                  </div>
                  <div class="ft">
                    <div class="d">{{ list.louceng13_7 }}</div>
                    <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                  </div>
          </li>
          <li id="reply8">
         <dl>
                      <dt>
                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                          <tr>
                            <td width="46" align="center" rowspan="2"><img :src="list.louceng14_1"  class="_avatar" width="36" height="36"/></td>
                            <td height="18"><span>{{ list.louceng14_2 }}</span></td>
                          </tr>
                          <tr>
                            <td style="font-size:14px;padding-top:5px;">{{ list.louceng14_3 }}</td>
                          </tr>
                        </table>
                      </dt>
                      <dd>
                        <div class="floor">{{ list.louceng14_4 }}</div>
                      </dd>
                    </dl>
                    <div class="rc">
                      <p>{{ list.louceng14_5 }}
                        <font color="#FF0000" size="+1">{{ list.louceng_zhuanjia }}</font>{{ list.louceng14_6 }}
                      </p>
                    </div>
                    <div class="ft">
                      <div class="d">{{ list.louceng14_7 }}</div>
                      <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                    </div>
          </li>
          <li id="reply3">
        <dl>
                        <dt>
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                              <td width="46" align="center" rowspan="2"><img :src="list.louceng15_1"  class="_avatar" width="36" height="36"/></td>
                              <td height="18"><span>{{ list.louceng15_2 }}</span></td>
                            </tr>
                            <tr>
                              <td style="font-size:14px;padding-top:5px;">{{ list.louceng15_3 }}</td>
                            </tr>
                          </table>
                        </dt>
                        <dd>
                          <div class="floor">{{ list.louceng15_4 }}</div>
                        </dd>
                      </dl>
                      <div class="rc">
                        <p>{{ list.louceng15_5 }}
                          <font color="#FF0000" size="+1">{{ list.louceng_zhuanjia }}</font>{{ list.louceng15_6 }}
                        </p>
                      </div>
                      <div class="ft">
                        <div class="d">{{ list.louceng15_7 }}</div>
                        <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                      </div>
          </li>
          <li id="reply3">
         <dl>
                          <dt>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr>
                                <td width="46" align="center" rowspan="2"><img :src="list.louceng16_1"  class="_avatar" width="36" height="36"/></td>
                                <td height="18"><span>{{ list.louceng16_2 }}</span></td>
                              </tr>
                              <tr>
                                <td style="font-size:14px;padding-top:5px;">{{ list.louceng16_3 }}</td>
                              </tr>
                            </table>
                          </dt>
                          <dd>
                            <div class="floor">{{ list.louceng16_4 }}</div>
                          </dd>
                        </dl>
                        <div class="rc">
                          <p>
                            {{ list.louceng16_5 }}
                          </p>
                        </div>
                        <div class="ft">
                          <div class="d">{{ list.louceng16_6 }}</div>
                          <div class="rbt" onClick="showReply(3,87964536);">{{ list.louceng_huifu }}</div>
                        </div>
          </li>

        </ul>
      </div>


      <div class="newTY" style="text-align: left;" id="newTY"  v-for="list in item.ask" :key="list">
        <div class="top">{{ list.ask1 }}</div>
        <ul>
          <li><a class="wxt" >{{ list.ask2 }}</a>
          </li>
          <li><a class="wxt" >{{ list.ask3 }}</a>
          </li>
          <li><a class="wxt" >{{ list.ask4 }}</a></li>

        </ul>
      </div>
      <!--相关问答 end  -->
      <div style="text-align:center;color:#ccc;font-size:12px;">
        <center>
          <p style="font-size: 12px;color:#CCC;"></p>
        </center><br /><br />
      </div>

    </div>
    <div class="hi_kong"></div>
    <div class="bottom-bar" style="text-align: center; width: 100%;position: fixed;bottom: 0;left:0;right: 0;margin: auto;height: 70px;border: 1px #eee solid;background-color: #fcfcfc;max-width: 640px;"  v-for="list in item.weixinhao" :key="list">
      <img :src="list.weixinhao1" width="45" style="float: left; margin-top: 10px;margin-left: 10px;">
      <div class="wx-left">{{ list.weixinhao2 }} <span style="color:red;font-weight:bold;"><span class="wx" style="font-weight: bold">{{ list.weixinhao3 }} </span></span><br>{{ list.weixinhao4 }} </div>
       
    </div>

 </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad8.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 